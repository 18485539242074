import styles from './stepper.module.css';
import {useOnboardingContext} from '../../../../context/onboarding-context';

export const Stepper = () => {
  const {step, data} = useOnboardingContext();

  return (
    <div className={styles.steps}>
      {data.pages.map((item, index) => (
        <div key={item.id} className={`${styles.step} ${step >= index ? styles.active : ''}`}>
          <div className={styles.line}/>
          <div className={styles.circle}/>
        </div>
      ))}
      <div className={styles.stepLast}>
        <div className={styles.line}/>
      </div>
    </div>
  )
}
