import React, { useContext, useEffect, useState } from "react";
import { DataType } from "../types";
import * as amplitude from "@amplitude/analytics-browser";
import { useLocalStorage } from "usehooks-ts";

type OnboardingContextType = {
  data: DataType;
  step: number;
  setStep: (step: number) => void;
  result: { [key in string]: string[] };
  setResult: (result: OnboardingContextType["result"]) => void;
};

const OnboardingCtx = React.createContext<
  OnboardingContextType | null | undefined
>(null);

interface IOnboardingContext {
  children?: React.ReactNode;
  data: DataType;
}

const OnboardingContext = ({ children, data }: IOnboardingContext) => {
  const [step, setStep] = useState(0);
  const [result, setResult] = useLocalStorage<OnboardingContextType["result"]>(
    "onboarding",
    {}
  );

  useEffect(() => {
    amplitude.track("onboarding_current_page", {
      id: String(step),
      onboarding_id: "onboarding_v1",
    });
  }, [step]);

  return (
    <OnboardingCtx.Provider
      value={{
        data,
        step,
        setStep,
        result,
        setResult,
      }}
    >
      {children}
    </OnboardingCtx.Provider>
  );
};

const useOnboardingContext = () => {
  const context = useContext(OnboardingCtx);

  if (!context) {
    throw new Error(`No Onboarding context provided`);
  }
  return context;
};

export { OnboardingContext, useOnboardingContext };
