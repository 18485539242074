import styles from './styles.module.css';
import {useOnboardingContext} from '../../../../context/onboarding-context';

export const Title = () => {
  const {step, data} = useOnboardingContext();
  const renderData = data.pages[step];

  return (
    <div className={styles.title}>
      <h3>{renderData.title.display}</h3>
      {renderData.subtitle && <p>{renderData.subtitle}</p>}
    </div>
  )
}
