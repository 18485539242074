import styles from "./onboarding.module.css";
import { Layout } from "../common-parts/layout";
import { Stepper } from "./components/stepper";
import { Loader } from "./components/loader";
import { Button } from "./components/button";
import { QuestionBlock } from "./components/question-block";
import { Footer } from "../common-parts/footer";
import { OnboardingContext } from "../../context/onboarding-context";
import * as amplitude from "@amplitude/analytics-browser";
import { useGetOnboardingData } from "../../hooks/useGetOnboardingData";
import { useEffect } from "react";

export const Onboarding = () => {
  useEffect(() => {
    amplitude.track("show_onboarding_screen", {
      id: "onboarding_v1",
    });
  }, []);

  const { data, isLoad } = useGetOnboardingData();

  if (isLoad || !data) {
    return null;
  }

  return (
    <Layout>
      <OnboardingContext data={data}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Loader />
              <Stepper />
            </div>
            <QuestionBlock />
          </div>
          <Button />
        </div>
        <Footer />
      </OnboardingContext>
    </Layout>
  );
};
