import BeforeAfterSlider from "../slider";
import {useOnboardingContext} from "../../../../context/onboarding-context";
import styles from "./styles.module.css";

export const Slider = () => {
  const {step, data} = useOnboardingContext();
  const {resources} = data.pages[step];

  return (
    <div className={styles.imageContainer}>
      <BeforeAfterSlider beforeImg={resources.images?.[0]?.url!} afterImg={resources.images?.[1]?.url!} handleResize={() => {}}/>
    </div>
  )
}
