import { useEffect, useRef, useState } from 'react';
import s from './styles.module.css';

type Props = {
  beforeImg: string;
  afterImg: string;
  handleResize: () => void;
};

const BeforeAfterSlider = ({ beforeImg, afterImg, handleResize }: Props) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [sliderWidth, setSliderWidth] = useState(0);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMove = (clientX: number, boundingRect: DOMRect) => {
    const x = Math.max(
      0,
      Math.min(clientX - boundingRect.left, boundingRect.width),
    );
    const percent = Math.max(0, Math.min((x / boundingRect.width) * 100, 100));
    setSliderPosition(percent);
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!isDragging) return;
    const rect = event.currentTarget.getBoundingClientRect();
    handleMove(event.clientX, rect);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const rect = event.currentTarget.getBoundingClientRect();
    handleMove(event.touches[0].clientX, rect);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleMouseUpOrTouchEnd = () => {
    setIsDragging(false);
  };

  const handleImageLoad = () => {
    if (imageRef.current) {
      const imageWidth = imageRef.current.offsetWidth;
      if (imageWidth > 0) {
        setSliderWidth(imageWidth);
      }
    }
  };

  return (
    <div
      className={s.slider}
      onMouseUp={handleMouseUpOrTouchEnd}
      onTouchEnd={handleMouseUpOrTouchEnd}
      style={{
        maxWidth: sliderWidth > 0 ? `${sliderWidth}px` : 'auto',
      }}
    >
      <div
        className={s.slider__images_container}
        onMouseMove={handleMouseMove}
        onTouchMove={handleTouchMove}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <img
          id="result"
          alt="after"
          className={s.after}
          src={afterImg}
          ref={imageRef}
          onLoad={handleImageLoad}
        />
        <div
          className={s.slider__before_img}
          style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
        >
          <img alt="before" src={beforeImg} />
        </div>
        <div
          className={s.slider__line}
          style={{
            left: `calc(${sliderPosition}% - 1px)`,
          }}
        >
          <div className={s.circle} />
        </div>
      </div>
      <p className={`${s.top_label} ${s.top_label_left}`}>Before</p>
      <p className={`${s.top_label} ${s.top_label_right}`}>After</p>
    </div>
  );
};

export default BeforeAfterSlider;
