const dev = [
  "price_1Q2ZTlPTLOkrCn0ed6wS5yWx",
  "price_1Q2ZX9PTLOkrCn0ecaD2rYQO",
];

const prod = [
  "price_1Q3IbXA3VB2ACp95wSD9wtXR",
  "price_1Q3IaxA3VB2ACp95CLvZJQOY",
];

export const getPaymentData = () =>
  process.env.REACT_APP_IS_PROD ? prod : dev;
