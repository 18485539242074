import { useEffect, useState } from 'react';
import qs from 'qs';
import onboardingConfig from '../data/onboarding-config.json';
import { DataType } from "../types";

export const useGetOnboardingData = () => {
    const [isLoad, setIsLoad] = useState(true);
    const [data, setData] = useState<DataType | null>(null);

    useEffect(() => {
        const onboarding = qs.parse(document.location.search, { ignoreQueryPrefix: true })['onboarding'] as string;
        const onboardingConfigItem = onboardingConfig.onboarding_items.find(item => item.id === onboarding);
        const path = onboardingConfigItem ? onboardingConfigItem.path : onboardingConfig.onboarding_items[0].path;
        
        fetch(`/onboarding-data/${path}`).then(response => {
            return response.json();
        }).then(response => {
            setData(response as DataType);
        }).finally(() => {
            setIsLoad(false);
        });

    }, []);

    return {
        isLoad,
        data,
    };
}