import { useOnboardingContext } from "../../../../context/onboarding-context";
import { Answers } from "./answers";
import { Image } from "./image";
import { Slider } from "./slider";
import { Email } from "./email";

export const Content = () => {
  const { step, data } = useOnboardingContext();
  const { type } = data.pages[step];

  if (type === "email") {
    return <Email />;
  }

  if (type === "image") {
    return <Image />;
  }

  if (type === "slider") {
    return <Slider />;
  }

  return <Answers />;
};
