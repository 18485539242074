import styles from "./button.module.css";
import { useOnboardingContext } from "../../../../context/onboarding-context";
import { sendUserPropertiesAmpl } from "../../utils/sendUserPropertiesAmpl";
import * as amplitude from "@amplitude/analytics-browser";

export const Button = () => {
  const { step, setStep, data, result } = useOnboardingContext();
  const stepData = data.pages[step];

  const onClick = () => {
    if (result["email"]?.length) {
      sendUserPropertiesAmpl("email", result["email"][0]);
    }

    if (stepData.id === "email") {
      amplitude.track("email_ready");
    }

    if (result["gender_picker"]?.length) {
      sendUserPropertiesAmpl("initial-gender", result["gender_picker"][0]);
    }

    if (stepData.id === "gender_picker" && result["gender_picker"]?.length) {
      amplitude.track("gender_picked", {
        gender: result["gender_picker"][0].toLowerCase(),
      });
    }

    if (data.pages.length - 1 > step) {
      setStep(step + 1);
    } else {
      const search = document.location.search || "";
      document.location.href = "/paywall" + search;
    }
  };

  const isDisabled =
    !(stepData?.resources?.is_skipable ?? true) && !result[stepData.id]?.length;

  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={onClick} disabled={isDisabled}>
        Continue
      </button>
    </div>
  );
};
