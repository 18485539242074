import { useMemo } from "react";
import { useReadLocalStorage } from "usehooks-ts";

export const useGetQueryParams = () => {
  const onboardingData = useReadLocalStorage<{ [key in string]: string[] }>("onboarding");
  
  return useMemo(() => {
    const result = [];

    if (onboardingData) {
      if (onboardingData["email"]?.length) {
        result.push(`email=${onboardingData["email"][0]}`);
      }

      if (onboardingData["gender_picker"]?.length) {
        result.push(`gender=${onboardingData["gender_picker"][0].toLowerCase()}`);
      }
    }

    return `${result.length ? '?' : ''}${result.join("&")}`;
  }, [onboardingData]);
};
