import styles from './footer.module.css';
import InstagramIcon from '../../../assets/instagram.svg';
import LinkedinIcon from '../../../assets/linkedin.svg';

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <p className={styles.copyright}>© 2024 Nufa / Mimesis inc</p>
      <div className={styles.social}>
        <a href='https://instagram.com'>
          <img src={InstagramIcon} alt='Instagram'/>
        </a>
        <a href='https://www.linkedin.com/'>
          <img src={LinkedinIcon} alt='Linkedin'/>
        </a>
      </div>
    </div>
  )
}
