import styles from "./styles.module.css";
import { Answer } from "./answer";
import { useOnboardingContext } from "../../../../context/onboarding-context";
import { useEffect, useMemo } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import defaulrGenders from "../../../../data/default-genders.json";

export const Answers = () => {
  const { step, data, result, setResult } = useOnboardingContext();
  const renderData = data.pages[step];

  useEffect(() => {
    if (renderData.type === "gender_picker") {
      amplitude.track("show_gender_picker_screen");
    }
  }, [renderData]);

  const checkType = useMemo(() => {
    return renderData.type === "gender_picker" ||
      renderData.resources.survey_max_answers_count === 1
      ? "radio"
      : "checkbox";
  }, [renderData]);

  const onCheck = (key: string, uncheck: boolean) => {
    if (
      result[renderData.id] &&
      Number(renderData.resources.survey_max_answers_count) <=
        result[renderData.id].length
    ) {
      return;
    }

    if (uncheck) {
      const arr = [...result[renderData.id]];
      const index = arr.indexOf(key);

      arr.splice(index, 1);
      setResult({ ...result, [renderData.id]: arr });

      return;
    }

    setResult({
      ...result,
      [renderData.id]:
        result[renderData.id] && checkType !== "radio"
          ? [...result[renderData.id], key]
          : [key],
    });
  };

  const surveyOptions = useMemo(() => {
    if (
      renderData.type === "gender_picker" &&
      !renderData.resources.survey_options?.length
    ) {
      return defaulrGenders;
    }

    return renderData.resources.survey_options;
  }, [renderData]);

  return (
    <div className={styles.answers}>
      {surveyOptions.map((item, index) => (
        <Answer
          key={item.title.key}
          inputName={renderData.id}
          type={checkType}
          name={item.title.key}
          label={item.title.display}
          index={index}
          onCheck={onCheck}
        />
      ))}
    </div>
  );
};
