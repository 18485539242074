import { Title } from "./title";
import styles from "./styles.module.css";
import { Content } from "./content";
import { useEffect } from "react";

export const QuestionBlock = () => {
  useEffect(() => {
    localStorage.removeItem('onboarding');
  }, []);

  return (
    <div className={styles.wrapper}>
      <Title />
      <Content />
    </div>
  );
};
