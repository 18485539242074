import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";

export const getTempAuthToken = async () => {
  let tempAuthToken = Cookies.get("temp-auth-token");

  if (tempAuthToken) {
    return tempAuthToken;
  }

  const setAuthTemp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL || ""}/api/v1/temp-auth`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            temp_id: amplitude.getDeviceId()!,
          }),
        }
      );

      if (!response.ok) {
        await setAuthTemp();
      }
      const data = await response.json();
      if (data) {
        tempAuthToken = data["auth-token"] as string;
        Cookies.set("temp-auth-token", tempAuthToken);
        // setTempAuthToken(tempAuthToken);
      }
    } catch (error) {
      setAuthTemp();
    }
  };

  await setAuthTemp();

  return tempAuthToken;
};
