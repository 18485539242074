import styles from "./styles.module.css";
import { useOnboardingContext } from "../../../../context/onboarding-context";
import errorIcon from "../../../../assets/error-icon.svg";
import { useEffect, useRef, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import * as amplitude from "@amplitude/analytics-browser";

export const Email = () => {
  const { step, data, result, setResult } = useOnboardingContext();
  const renderData = data.pages[step];
  const [isError, setIsError] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const [debouncedValue, setValue] = useDebounceValue("", 500);

  useEffect(() => {
    amplitude.track("show_email_screen");
  }, []);

  useEffect(() => {
    setIsError(false);

    if (ref.current?.checkValidity()) {
      if (debouncedValue) {
        setResult({ ...result, [renderData.id]: [debouncedValue] });
      }
    } else {
      setResult({ ...result, [renderData.id]: [] });
      setIsError(true);
    }
  }, [debouncedValue]);

  return (
    <>
      <div>
        {isError && (
          <div className={styles.inputError}>
            <img src={errorIcon} alt="error icon" />
            Invalid email address. Please try again.
          </div>
        )}
        <div className={styles.emailInputContainer}>
          <div className={styles.emailinputIcon} />
          <input
            ref={ref}
            placeholder="E-mail"
            className={`${styles.emailInput} ${
              isError ? styles.emailInputError : ""
            }`}
            type="email"
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.privacy}>
        Your information is 100% secure. We don’t sell your personal
        information. By submitting your email adress, you confirm you are over
        18 years and agree to{" "}
        <a
          href="https://nufa.ai/terms/"
          onClick={() => amplitude.track("email_terms_tap")}
        >
          Terms of use
        </a>{" "}
        and{" "}
        <a
          href="https://nufa.ai/privacy/"
          onClick={() => amplitude.track("email_privacy_tap")}
        >
          Privacy policy
        </a>
      </div>
    </>
  );
};
