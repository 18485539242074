import styles from "./styles.module.css";

interface Props {
  type: "radio" | "checkbox";
  name: string;
  label: string;
  index: number;
  icon?: string;
  onCheck: (key: string, uncheck: boolean) => void;
  inputName: string;
}

export const Answer = ({
  name,
  label,
  index,
  type,
  icon,
  onCheck,
  inputName,
}: Props) => {
  const id = `${name}-${index}`;

  const onClick = (checked: boolean) => {
    onCheck(name, !checked);
  };

  return (
    <div className={styles.answer}>
      <input
        type={type}
        name={inputName}
        id={id}
        onChange={(e) => onClick(e.target.checked)}
      />
      <label htmlFor={id} className={styles.inputLabel}>
        <span>{icon}</span> {label}
      </label>
    </div>
  );
};
