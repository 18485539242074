import {useOnboardingContext} from "../../../../context/onboarding-context";
import styles from './styles.module.css';

export const Image = () => {
  const {step, data} = useOnboardingContext();
  const {resources, title, } = data.pages[step];

  return (
    <div className={styles.imageContainer}>
      <img src={resources.images?.[0]?.url} className={styles.image} alt={title.display} style={{
        backgroundColor: resources.images?.[0]?.color,
      }}
      />
    </div>
  )
}
